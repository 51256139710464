<template>
    <div>
    </div>
</template>

<script>
    import auth from './../../plugins/auth';

    export default {
        mounted() {
            auth.clearAuth();
            this.$toast.add({
                severity: 'success',
                summary: this.$t('Success'),
                detail: this.$t('Logout successful'),
                life: 3000
            });
            this.$router.push('/login');
        }
    }
</script>
